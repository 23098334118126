<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Invoice</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="addInvoice"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-2"
                >
                  <CustomerAutocomplete
                    :form-errors="form.errors"
                    :initial-customer-id="userId"
                    :show-dialog="showDialog"
                    @update="updateCustomerId"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.amount"
                    label="Amount"
                    placeholder="e.g. 1000"
                    outlined
                    hint="Input numbers only, no commas"
                    :rules="[v => !!v || 'required', v => v > 0 || 'The value must be greater than 0']"
                    :error="form.errors.has('amount')"
                    :error-messages="form.errors.get('amount')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.description"
                    label="Description"
                    placeholder="e.g Installation Fee"
                    hint="Provide a brief description of the invoice."
                    rows="1"
                    outlined
                    :error="form.errors.has('description')"
                    :error-messages="form.errors.get('description')"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <date-time-picker
                    :label="'Due Date'"
                    :default-date-time="new Date().toISOString()"
                    :min-date="new Date().toISOString()"
                    mode="date"
                    @change="updateDueDate"
                  />
                  <small
                    v-show="form.errors.has('due_date')"
                    class="validation-error"
                  >
                    {{ form.errors.get('due_date') }}
                  </small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import moment from 'moment'
import CustomerAutocomplete from '@/components/partials/CustomerSearchAutocomplete.vue'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  components: {
    CustomerAutocomplete,
    DateTimePicker,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    form: new Form({
      customer_id: '',
      amount: '',
      description: '',
      due_date: moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }),
    action: 'Add',
  }),
  methods: {
    updateCustomerId(newCustomerId) {
      this.form.customer_id = newCustomerId
    },
    closeDialog() {
      this.$emit('close')
      if (this.userId) {
        this.form.amount = ''
        this.form.description = ''
        this.form.due_date = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD')
      } else {
        this.$refs.form.reset()
      }
    },
    addInvoice() {
      this.form
        .post('invoices')
        .then(() => {
          this.$toast.success('Invoice added successfully')
          this.closeDialog()
          this.$emit('invoiceAdded')
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateDueDate(newDate) {
      this.form.due_date = moment(newDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
