<template>
  <div class="invoices">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="text-md-right"
      >
        <v-btn
          v-if="can('invoice-create')"
          color="primary"
          class="mb-2"
          @click="showAddInvoiceDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Invoice
        </v-btn>
      </v-col>
    </v-row>
    <invoices-table
      :invoices-url="'invoices'"
      :show-add-invoice-dialog="showAddInvoiceDialog"
      @close="showAddInvoiceDialog = false"
    />
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import Breadcrumb from '@/components/partials/BreadCrumb.vue'
import InvoicesTable from '@/components/tables/InvoicesTable.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    Breadcrumb,
    InvoicesTable,
  },
  mixins: [
    hasPermission,
  ],
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        { text: 'Invoices', disabled: true },
      ],
      icons: {
        mdiPlus,
      },
      showAddInvoiceDialog: false,
    }
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>
