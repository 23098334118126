<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="620"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedDateTime"
        :label="label"
        readonly
        outlined
        v-on="on"
      />
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <span v-if="formattedDateTime">
              Selected: {{ formattedDateTime }}
            </span>
            <span v-else>
              Please select a {{ isDateOnly ? 'date' : 'date and time' }}.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <!-- Date Picker takes full width if in date mode -->
          <v-col :cols="isDateOnly ? 12 : 6">
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :min="formattedMinDate"
              :max="formattedMaxDate"
              class="w-100"
            >
              <template v-slot:day="{ date }">
                {{ date.getDate() }}
              </template>
            </v-date-picker>
          </v-col>
          <!-- Time Picker is only shown if mode is datetime -->
          <v-col
            v-if="!isDateOnly"
            cols="6"
          >
            <v-time-picker
              v-model="time"
              format="ampm"
              ampm-in-title
              scrollable
            >
              <template v-slot:ampm="{ period }">
                {{ period.toUpperCase() }}
              </template>
            </v-time-picker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveDateTime"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    label: {
      type: String,
      default: 'Date Time',
    },
    defaultDateTime: {
      type: [String, Date],
      default: null,
    },
    minDate: {
      type: [String, Date],
      default: null,
    },
    maxDate: {
      type: [String, Date],
      default: null,
    },
    mode: {
      type: String,
      default: 'datetime', // Can be 'datetime' or 'date'
      validator: value => ['datetime', 'date'].includes(value),
    },
  },
  data() {
    const defaultDateTimeMoment = this.defaultDateTime
      ? moment(this.defaultDateTime)
      : moment()

    return {
      date: defaultDateTimeMoment.format('YYYY-MM-DD'),
      time: this.mode === 'datetime' ? defaultDateTimeMoment.format('HH:mm') : null,
      dialog: false,
    }
  },
  computed: {
    isDateOnly() {
      return this.mode === 'date'
    },
    formattedMinDate() {
      return this.minDate ? moment(this.minDate).format('YYYY-MM-DD') : null
    },
    formattedMaxDate() {
      return this.maxDate ? moment(this.maxDate).format('YYYY-MM-DD') : null
    },
    formattedDateTime() {
      if (!this.date) return null

      if (this.isDateOnly) {
        return moment(this.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }

      if (this.date && this.time) {
        return moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY h:mm A')
      }

      return null
    },
  },
  watch: {
    defaultDateTime() {
      const defaultDateTimeMoment = this.defaultDateTime
        ? moment.utc(this.defaultDateTime).local()
        : null

      this.date = defaultDateTimeMoment ? defaultDateTimeMoment.format('YYYY-MM-DD') : null
      this.time = !this.isDateOnly && defaultDateTimeMoment ? defaultDateTimeMoment.format('HH:mm') : null
    },
  },
  methods: {
    saveDateTime() {
      this.dialog = false
      this.$emit('change', this.formattedDateTime)
    },
  },
}
</script>
